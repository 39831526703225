.uxf-label {
    @apply mb-3 block text-overline-small font-bold uppercase;

    :root .light & {
        @apply text-gray-900;
    }

    :root .dark & {
        @apply text-white;
    }

    &--hidden {
        @apply sr-only;
    }

    &.is-required {
        &::after {
            @apply opacity-75;

            content: "*";
        }
    }
}
