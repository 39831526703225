.uxf-textarea {
    &__wrapper {
        @apply rounded-lg border-2 p-4 text-body;

        :root .light & {
            @apply border-gray-200 bg-gray-100 text-gray-900;
        }

        :root .dark & {
            @apply border-uxfMuted75 bg-uxfVioletLight text-white;
        }
    }

    &__element {
        @apply w-full resize-none bg-transparent outline-none;

        :root .light & {
            @apply placeholder:text-gray-400;
        }

        :root .dark & {
            @apply placeholder:text-uxfMuted75;
        }
    }

    &.is-focused {
        .uxf-textarea__wrapper {
            @apply ring-2 ring-inset ring-offset-0;

            :root .light & {
                @apply ring-uxfViolet;
            }

            :root .dark & {
                @apply ring-white/75;
            }
        }
    }

    &.is-disabled {
        @apply cursor-not-allowed;

        .uxf-textarea__wrapper {
            @apply opacity-50;
        }

        .uxf-textarea__element {
            @apply cursor-not-allowed;
        }
    }

    &.is-readonly {
        @apply cursor-text;

        .uxf-textarea__element {
            @apply pointer-events-none cursor-not-allowed;
        }

        .uxf-textarea__wrapper {
            @apply ring-0;

            :root .light & {
                @apply border-gray-300;
            }

            :root .dark & {
                @apply border-uxfMuted75;
            }
        }
    }

    &.is-invalid {
        &.is-focused {
            .uxf-textarea__wrapper {
                @apply ring-0;
            }
        }

        .uxf-textarea__wrapper {
            @apply border-uxfOrange;

            .uxf-textarea__element {
                :root .light & {
                    @apply text-uxfOrange;
                }

                :root .dark & {
                    @apply text-uxfOrange;
                }
            }
        }
    }

    :root .dark & {
        input:-webkit-autofill {
            &,
            &:hover,
            &:focus {
                -webkit-text-fill-color: theme("colors.white");
                box-shadow: inset 0 0 0 1000px theme("colors.uxfVioletLight");
                caret-color: white;
                margin: 1px 0;
            }
        }
    }
}
