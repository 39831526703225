@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@layer base {
    @font-face {
        font-display: optional;
        font-family: inter-custom;
        font-style: normal;
        font-weight: 400;
        src:
            local("Inter Regular"),
            local("Inter-Regular"),
            local("Inter"),
            url("/static/fonts/inter/inter-v11-latin-ext_latin-regular.woff2") format("woff2"),
            url("/static/fonts/inter/inter-v11-latin-ext_latin-regular.woff") format("woff");
    }

    @font-face {
        font-display: swap;
        font-family: inter-custom;
        font-style: normal;
        font-weight: 600;
        src:
            local("Inter SemiBold"),
            local("Inter-SemiBold"),
            url("/static/fonts/inter/inter-v11-latin-ext_latin-600.woff2") format("woff2"),
            url("/static/fonts/inter/inter-v11-latin-ext_latin-600.woff") format("woff");
    }

    @font-face {
        ascent-override: 90%;
        font-family: inter-fallback;
        size-adjust: 107%;
        src: local("Arial");
    }

    body {
        color: theme("colors.uxfViolet");
        overflow-x: hidden;
    }
}

@layer components {
    ._button {
        @apply relative inline-flex text-center text-xl font-bold text-[color:var(--btn-color)] transition duration-300 is-hoverable:-translate-y-2 is-hoverable:before:translate-y-2 is-hoverable:before:opacity-50 is-hoverable:after:translate-y-4 is-hoverable:after:opacity-25;

        &__inner {
            @apply z-10 flex w-full items-center justify-center rounded-full;

            &--default {
                @apply min-h-[5rem] px-10 py-4;
            }

            &--small {
                @apply min-h-[4rem] px-4 py-2;
            }
        }

        &::before,
        &::after {
            content: "";

            @apply absolute inset-0 rounded-full border-2 border-current opacity-0 transition-transform;
        }
    }

    ._icon {
        @apply h-[--i-h] w-[--i-w] shrink-0;

        & > use {
            @apply pointer-events-none;
        }
    }

    .ui-typo-hero {
        @apply text-[3rem] font-bold md:text-[4.5rem] lg:text-hero;

        line-height: 1.33;
    }

    .ui-typo-h1 {
        @apply text-[2.375rem] font-bold md:text-[4rem] lg:text-h1;

        line-height: 1.33;
    }

    .ui-typo-h2 {
        @apply text-[2rem] font-bold md:text-[3.25rem] lg:text-h2;

        line-height: 1.33;
    }

    .ui-typo-h3 {
        @apply text-[1.75rem] font-bold leading-snug md:text-[2.5rem] md:leading-snug lg:text-h3;

        line-height: 1.33;
    }

    .ui-typo-h4 {
        @apply text-[1.5rem] font-bold md:text-[2rem] lg:text-h4;

        line-height: 1.33;
    }

    .ui-typo-h5 {
        @apply text-[1.25rem] font-bold md:text-h5;

        line-height: 1.33;
    }

    .ui-typo-super-large {
        @apply text-[1.5rem] md:text-[2.25rem] lg:text-super-large;

        line-height: 1.5;
    }

    .ui-typo-large {
        @apply text-[1.5rem] md:text-[1.75rem] lg:text-large;

        line-height: 1.5;
    }

    .ui-typo-medium {
        @apply text-[1.25rem] md:text-medium;

        line-height: 1.5;
    }

    .ui-typo-body {
        @apply text-[1rem] md:text-body;

        line-height: 1.5;
    }

    .ui-typo-small1 {
        @apply text-small1;

        line-height: 1.5;
    }

    .ui-typo-small2 {
        @apply text-small2;

        line-height: 1.5;
    }

    .ui-typo-small3 {
        @apply text-small3;

        line-height: 1.5;
    }

    .ui-typo-overline-large {
        @apply text-[1rem] font-bold uppercase md:text-overline-large;
    }

    .ui-typo-overline-small {
        @apply text-overline-small font-bold uppercase;
    }

    .ui-container {
        @apply container px-6 md:px-0;
    }

    .hover-stripes {
        @apply relative transition duration-300 is-hoverable:before:opacity-50 is-hoverable:after:opacity-25;

        &--t {
            @apply is-hoverable:-translate-y-2 is-hoverable:before:translate-y-2 is-hoverable:after:translate-y-4;
        }

        &--tr {
            @apply is-hoverable:-translate-y-2 is-hoverable:translate-x-2 is-hoverable:before:-translate-x-2 is-hoverable:before:translate-y-2 is-hoverable:after:-translate-x-4 is-hoverable:after:translate-y-4;
        }

        &::before,
        &::after {
            content: "";
            z-index: -1;

            @apply absolute inset-0 rounded-inherit border-2 border-current opacity-0 transition-transform;
        }
    }
}

#nprogress {
    pointer-events: none;

    .bar {
        background: theme("colors.uxfOrangeHover");
        height: 0.25rem;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10310;
    }

    .peg {
        display: none;
    }
}

.uxf-helper-text {
    @apply mt-1 text-xs text-gray-500;

    &.is-invalid {
        @apply text-uxfOrange;
    }
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(var(--scroll-length)));
    }
}
