.uxf-tabs {
    &__button-list {
        border-bottom: theme("borderWidth.DEFAULT") solid theme("borderColor.gray.200");
        color: theme("colors.uxfBaseText");
        display: flex;
        font-size: theme("fontSize.body");
        gap: theme("spacing.4");
        overflow-x: auto;
        scrollbar-width: none;
        white-space: nowrap;

        @screen lg {
            font-size: theme("fontSize.medium");
        }
    }

    &__button {
        @apply text-uxfBaseText/55 transition-opacity;

        padding: theme("spacing.4") theme("spacing.2");
        position: relative;

        &.is-active {
            color: theme("colors.uxfViolet");
            font-weight: theme("fontWeight.semibold");
            opacity: 1;

            &::before {
                border: theme("borderWidth.2") solid theme("colors.uxfViolet");
                inset-inline: 0;
                bottom: 0;
                content: "";
                position: absolute;
            }
        }

        &:hover:not(.is-active) {
            @apply text-uxfBaseText/70;
        }
    }
}
