.uxf-radio-group {
    &--row {
        .uxf-radio-group__options-wrapper {
            align-items: center;
            display: flex;

            @apply space-x-4 sm:space-x-10;
        }
    }

    &--list {
        .uxf-radio-group__option {
            border: none;
        }

        .uxf-radio-group__option-wrapper {
            align-items: center;
            display: flex;
            flex-direction: row-reverse;
            gap: 8px;
            justify-content: flex-end;
            padding: 12px 0;
        }
    }
}
