.uxf-input {
    @apply rounded-lg text-body;

    :root .light & {
        @apply bg-gray-100 text-gray-900;
    }

    :root .dark & {
        @apply bg-uxfVioletLight text-gray-300;

        input:-webkit-autofill {
            &,
            &:hover,
            &:focus {
                -webkit-text-fill-color: theme("colors.white");
                box-shadow: inset 0 0 0 1000px theme("colors.uxfVioletLight");
                caret-color: white;
                margin: 1px 0;
            }
        }
    }

    &__element {
        @apply bg-transparent outline-none;

        :root .light & {
            @apply placeholder:text-gray-400;
        }

        :root .dark & {
            @apply placeholder:text-uxfMuted75;
        }
    }

    &__left-element {
        @apply mr-4;
    }

    &__right-element {
        @apply ml-4;
    }

    &__left-addon,
    &__right-addon {
        @apply border-2 px-4;

        :root .light & {
            @apply border-gray-200;
        }

        :root .dark & {
            @apply border-uxfMuted75;
        }
    }

    &__left-addon {
        @apply rounded-l-lg border-r-0;
    }

    &__right-addon {
        @apply rounded-r-lg border-l-0;
    }

    &__wrapper {
        @apply h-12 rounded-lg border-2 px-4;

        :root .light & {
            @apply border-gray-200 bg-white;
        }

        :root .dark & {
            @apply border-uxfMuted75 bg-uxfVioletLight;
        }
    }

    &--has-right-addon {
        .uxf-input__wrapper {
            @apply rounded-r-none;
        }
    }

    &--has-left-addon {
        .uxf-input__wrapper {
            @apply rounded-l-none;
        }
    }

    &.is-focused {
        .uxf-input__wrapper {
            @apply ring-1 ring-inset ring-offset-0;

            :root .light & {
                @apply border-uxfViolet ring-uxfViolet;
            }

            :root .dark & {
                @apply border-white/75 ring-white/75;
            }
        }
    }

    &.is-disabled {
        @apply cursor-not-allowed opacity-50;

        .uxf-input__element {
            @apply cursor-not-allowed;
        }
    }

    &.is-readonly {
        @apply cursor-text;

        .uxf-input__element {
            @apply cursor-text;
        }

        .uxf-input__wrapper {
            @apply ring-0;
        }

        .uxf-input__wrapper,
        .uxf-input__left-addon,
        .uxf-input__right-addon {
            :root .light & {
                @apply border-gray-300;
            }

            :root .dark & {
                @apply border-uxfMuted75;
            }
        }
    }

    &.is-invalid {
        &.is-focused {
            .uxf-input__wrapper {
                :root .light & {
                    @apply border-uxfOrange ring-uxfOrange;
                }

                :root .dark & {
                    @apply border-uxfOrange ring-uxfOrange;
                }
            }
        }

        .uxf-input__wrapper {
            @apply border-uxfOrange placeholder:text-uxfOrange;

            .uxf-input__left-element,
            .uxf-input__right-element {
                @apply text-uxfOrange;
            }

            .uxf-input__element {
                :root .light & {
                    @apply border-gray-300 text-uxfOrange;
                }

                :root .dark & {
                    @apply border-gray-500 text-uxfOrange;
                }
            }
        }
    }
}
